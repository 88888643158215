const musicFiltersSuccess = (payload) => ({
  type: "MUSIC_FILTERS_SUCCESS",
  payload,
});

const musicFiltersFailure = (error) => ({
  type: "MUSIC_FILTERS_FAILURE",
  error,
});

const resetmusicFiltersFlag = () => ({ type: "MUSIC_FILTERS_RESET" });

const musicFiltersCall = () => ({
  type: "MUSIC_FILTERS_API_CALL",
});
export {
  musicFiltersSuccess,
  musicFiltersFailure,
  resetmusicFiltersFlag,
  musicFiltersCall,
};
