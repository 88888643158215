import React, { useState } from "react";
import "./home.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../login";
import logo from "../../assets/brandmusic_logo.svg";
const Home = () => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const showModel = () => {
    setOpen(true);
  };
  return (
    <div className="home-page">
      <div className="sections">
        <div className="section1">
          <div>
            <img src={logo} alt="" className="logo"></img>
          </div>
          <div className="sonic-revo">Embrace the Sonic Revolution:</div>
          <div className="Ai-tech">
            AI / Tech Powered Soundscapes for Your Brand's Success!
          </div>
          <div className="composed"> Composed in minutes...</div>
          <div className="composed-text">
            The human ear is 20X faster than the eye. Research shows, sound has
            the power to increase customer immersion and engage audiences by up
            to 86%. Couple this with a surge in voice-enabled technology and a
            worldwide resurgence in audio consumption, and there has never been
            a better time to move your brand identity from cognition to emotion
            with sonic branding.
          </div>

          <Login onClick={showModel} showModel={isOpen}></Login>
        </div>
      </div>
    </div>
  );
};

export default Home;
