import React, { useEffect, useState } from "react";
import "./suggestedSonicMusic.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getgeneratedRelatedMusicCall } from "../../redux/apiCall/getGenerateMusicDataApi";
import { getData } from "../../utils/storageService";
import axios from "axios";
import ReactPlayer from "react-player";
const SuggestedMusic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showRelatedMusic, setShowRelatedMusic] = useState(false);
  const queryParam = new URLSearchParams(location.search);
  const { data: relatedMusic, loading: relatedMusicLoading } = useSelector(
    (state) => state.generatedRelatedMusicData
  );
  useEffect(() => {
    const companyName = queryParam.get("companyName");
    dispatch(getgeneratedRelatedMusicCall(companyName));
    // queryParam.set("avtar", avtar);
  }, []);
  useEffect(() => {
    relatedMusic.length > 0 && setShowRelatedMusic(true);
  }, [relatedMusic]);

  const [musicFileUrls, setMusicFileUrls] = useState({});
  const getMusicFile = async (link) => {
    try {
      const response = await axios.get(link, {
        headers: {
          Authorization: getData("Token"),
        },
        responseType: "blob",
      });
      const audioUrl = window.URL.createObjectURL(new Blob([response.data]));
      return audioUrl;
    } catch (error) {}
  };
  // useEffect(() => {
  //   relatedMusic.length > 0 && setShowRelatedMusic(true);
  //   if (relatedMusic.length > 0) {
  //     relatedMusic.map(async (ele) => {
  //       const url = await getMusicFile(ele.link);
  //       setMusicFileUrls({ ...musicFileUrls, [ele.id]: url });
  //     });
  //   }
  // }, [relatedMusic]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const renderData = () => {
    // const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

    const playSong = (ele) => {
      if (currentlyPlaying === ele.link) {
        setCurrentlyPlaying(null);
      } else {
        setCurrentlyPlaying(ele.link);
      }
    };

    return (
      <div>
        {relatedMusic.map((ele, idx) => (
          <div className="music-card" key={idx}>
            <div className="values">
              <div className="percentage">{ele?.matchedPercentage}%</div>
              <div className="match">Match</div>
            </div>

            <div>
              <ReactPlayer
                url={ele.link}
                height={"5vw"}
                width={"40vw"}
                controls
                onPlay={() => playSong(ele)}
                playing={ele.link === currentlyPlaying}
              />
              {/* <button onClick={() => playSong(ele)}>
                {ele.link === currentlyPlaying ? "Pause" : "Play"}
              </button> */}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="suggested-music">
      <div className="details">
        <div className="title">Suggested Sonic Music</div>
        {showRelatedMusic && <div className="render-data">{renderData()}</div>}
      </div>
    </div>
  );
};

export default SuggestedMusic;
