import React from "react";
import ReactApexChart from "react-apexcharts";

const RadialbarChart = ({
  emailSentPercentage,
  emailUnSentPercentage,
  allEmailCount,
  emailLinkOpenedPercentage,
  youtubeVideoWatchedPercentage,
}) => {
  let series = [
    emailSentPercentage,
    emailUnSentPercentage,
    emailLinkOpenedPercentage,
    youtubeVideoWatchedPercentage,
  ];
  let options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total Email ",
            formatter: function (w) {
              return allEmailCount;
            },
          },
        },
      },
    },
    labels: [
      "Email Sent",
      "Email Unsend",
      "Email Opened",
      "Youtube Video Watched",
    ],
  };

  return (
    allEmailCount && (
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={500}
        width={600}
      />
    )
  );
};

export default RadialbarChart;
