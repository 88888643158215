const dashboardDataSuccess = (payload) => ({
  type: "DASHBOARDDATA_SUCCESS",
  payload,
});

const dashboardDataFailure = (error) => ({
  type: "DASHBOARDDATA_FAILURE",
  error,
});

const resetdashboardDataFlag = () => ({ type: "DASHBOARDDATA_RESET" });

const dashboardDataCall = () => ({
  type: "DASHBOARDDATA_API_CALL",
});
export {
  dashboardDataSuccess,
  dashboardDataFailure,
  resetdashboardDataFlag,
  dashboardDataCall,
};
