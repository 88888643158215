import React, { useEffect, useState } from "react";
import "./dashBoard.scss";
import RadialbarChart from "../../components/charts/radialBarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Linechart from "../../components/charts/lineChart";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getBrandsFiltersCall } from "../../redux/apiCall/getBrandsFiltersApi";
import { getdashboardCall } from "../../redux/apiCall/getDashboardDataApi";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const location = useLocation();
  const { data: filtersData, loading: filtersLoading } = useSelector(
    (state) => state.filters
  );
  const { data: dashboardData, loading: dashboardDataLoading } = useSelector(
    (state) => state.dashboardData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    queryParam.set("areaRequested", selectedArea);
    queryParam.set("sectorRequested", selectedSector);
    dispatch(getBrandsFiltersCall());
    dispatch(getdashboardCall(queryParam));
  }, [selectedSector]);
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    queryParam.set("areaRequested", selectedArea);
    queryParam.set("sectorRequested", selectedSector);
    dispatch(getBrandsFiltersCall());
    dispatch(getdashboardCall(queryParam));
  }, [selectedArea]);

  return (
    dashboardData && (
      <div className="dashboard">
        <div className="count-filter">
          {" "}
          <div className="count">
            <div className="value">{dashboardData?.total_email_count}</div>
            <p>All Companies</p>
          </div>
          <div className="count">
            <div className="value">{dashboardData?.email_send_count}</div>
            <p>Email Sent</p>
          </div>
          <div className="count">
            <div className="value">
              {dashboardData?.total_email_count -
                dashboardData?.email_send_count}
            </div>
            <p>Email Unsend</p>
          </div>
          {/* <div className="count">
            <div className="value">245</div>
            <p>Email Unsend</p>
          </div> */}
          <div className="filters">
            <Select
              mode="multiple"
              placeholder="Sectors"
              onChange={setSelectedSector}
              options={filtersData?.sectorList?.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <Select
              mode="multiple"
              placeholder="Geographical Area"
              onChange={setSelectedArea}
              options={filtersData?.areaList?.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </div>
        </div>
        <div className="charts">
          <div className="radial-chart">
            <RadialbarChart
              emailSentPercentage={dashboardData?.send_email_percentage}
              emailUnSentPercentage={dashboardData?.unsent_email_percentage}
              allEmailCount={dashboardData?.total_email_count}
              emailLinkOpenedPercentage={dashboardData?.link_opened_percentage}
              youtubeVideoWatchedPercentage={
                dashboardData?.youtube_opened_percentage
              }
            />
            <div className="labels">
              <div className="text">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: "#3B7FD8", fontSize: "1.5vw" }}
                />{" "}
                Percentage of Emails have been sent to companies{" "}
                <div className="value">
                  {dashboardData?.send_email_percentage || "-"}
                </div>
              </div>
              <div className="text">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: "#5FC48E", fontSize: "1.5vw" }}
                />{" "}
                Percentage of Emails have not been sent to companies{" "}
                <div className="value">
                  {" "}
                  {dashboardData?.unsent_email_percentage || "-"}
                </div>
              </div>
              <div className="text">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: "#D59F43", fontSize: "1.5vw" }}
                />{" "}
                Percentage of Email link opened by companies{" "}
                <div className="value">
                  {dashboardData?.link_opened_percentage || "-"}
                </div>
              </div>
              <div className="text">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: "#E95FFF", fontSize: "1.5vw" }}
                />{" "}
                Percentage of youtube video watched{" "}
                <div className="value">
                  {dashboardData?.youtube_opened_percentage || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="line-chart">
            <Linechart
              graphData={dashboardData.graphData}
              // emailLinkOpenedPercentage={dashboardData?.email_link_opened_percentage}
              // youtubeVideoWatchedPercentage={dashboardData?.youtube_video_watched_percentage}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default Dashboard;
