import React, { useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoComplete /* , Dropdown, Space, Typography */ } from "antd";

import "./searchBox.scss";
const SearchBox = ({
  placeholder,
  value,
  brandName,
  avtar,
  navrasa,
  onClick,
  onSearch,
  onChange,
  type,
}) => {
  const [searchText, setSearchText] = useState("");
  
  return (
    <form
      autoComplete="on"
      typeof="submit"
      onSubmit={(e) => (e.preventDefault(), onClick())}
    >
      <div className="search-box">
        <AutoComplete
          placeholder={placeholder}
          className="input-box"
          onChange={(e) => onChange(e)}
          // onSearch={onSearch}
          value={value}

          // onSearch={onSearch}
        ></AutoComplete>
        <button className="search-btn" type="submit">
          <FontAwesomeIcon
            className="search-icon"
            icon={faMagnifyingGlass}
            onClick={onClick}
          />
        </button>
      </div>
    </form>
  );
};

export default SearchBox;
