import "./App.scss";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/home/home";
import logo from "./assets/brandmusic_logo.svg";
import Login from "./pages/login";
import AuthRoute from "./components/authRoute";

import { Dropdown, Space, Typography } from "antd";
import UserIcon from "./assets/user.svg";
import { clearSession } from "./utils/sessionManagement";
import { discardHeader } from "./utils/apiClient";
import { resetLoginFlag } from "./redux/action/loginActions";
import { useDispatch, useSelector } from "react-redux";
import SuggestedMusic from "./pages/suggestedSonicMusic/suggestSonicMusic";
import BrandDetails from "./pages/brandDetails/brandDetails";
import { useEffect, useRef, useState } from "react";
import notifyUtil from "./utils/notifyUtil";
import { resetCommon } from "./redux/action/apiAction";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.min.css";
import BrandInfo from "./pages/brandInfo/brandInfo";
import Tab from "./pages/tabs/tabs";
import MusicTab from "./pages/tabs/musicTab";
import BrandsTab from "./pages/tabs/brandsTab";
import GenerateMail from "./pages/generatemail/generateemail";

import SearchBox from "./components/searchBox/searchBox";
import Dashboard from "./pages/tabs/dashBoard";
import CompanyReport from "./pages/tabs/companyReport";
function App() {
  const [searchText, setSearchText] = useState("");

  const [hideback, setHideback] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isError, error } = useSelector((state) => state.apiStatus);
  const ref = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const queryParam = new URLSearchParams(location.search);

  useEffect(() => {
    scrollToTop();
    setSearchText(queryParam.get("search"));
  }, [location.search]);
  // const isLogin = true;
  useEffect(() => {
    if (isError) {
      const response = error.response;
      if (response) {
        notifyUtil(error.response.data?.message, "error", {
          toastId: "common-error",
        });
        if (response.status === 401) {
          clearSession();
          discardHeader();
          dispatch(resetLoginFlag());
          if (location.pathname !== "/login") {
            navigate("/");
          }
        }
      } else {
        notifyUtil(
          "Something went wrong please try again after some time",
          "error",
          { toastId: "common-error" }
        );
        navigate("/");
      }
    }
    dispatch(resetCommon());
  }, [isError]);

  let search = location.pathname === "/tabs/brands" ? "Brands" : "Music";

  const onSearch = () => {
    const queryParam = new URLSearchParams(location.search);
    location.pathname === "/tabs/brands"
      ? queryParam.set("search", searchText)
      : queryParam.set("musicSearch", searchText);
    queryParam.set("pageNo", 0);
    navigate(`${location.pathname}?${queryParam.toString()}`);
  };


  // const [searchText,setSearchText]=useState("")
  const items = [
    // {
    //   key: "0",
    //   label: "Profile",
    // },
    {
      key: "1",
      label: "Logout",
    },
  ];
  const onClick = () => {
    clearSession();
    discardHeader();
    localStorage.removeItem("Token");
    dispatch(resetLoginFlag());
    navigate("/");
  };
  return (
    <div className="App">
      <ToastContainer className="toaster" />

      <div className="background" ref={ref}>
        {location.pathname !== "/" && (
          <div className="header-nav">
            <div className="logo-search">
              <img
                src={logo}
                alt="brandmusic"
                className="brandmusic-logo"
                onClick={() => {
                  navigate("/tabs/brands?fetchSize=20&pageNo=0");
                }}
              />
              {(location.pathname === "/tabs/brands" ||
                location.pathname === "/tabs/music") &&
                !queryParam.get("viewAll") && (
                  <SearchBox
                    placeholder={`Search ${search}`}
                    // onSearch={onSearch}
                    onClick={onSearch}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                    type="submit"
                    value={searchText}
                  ></SearchBox>
                )}
            </div>
            {/* <form
              autoComplete="on"
              typeof="submit"
              onSubmit={(e) => onSubmitData(e)}
            >
              <div className="search-box">
                <AutoComplete
                  placeholder="Search Music"
                  className="input-box"
                  onChange={(e) => setSearchText(e)}
                  value={searchText}

                  // onSearch={onSearch}
                ></AutoComplete>
                <button className="search-btn" type="submit">
                  <FontAwesomeIcon
                    className="search-icon"
                    icon={faMagnifyingGlass}
                    // type="submit"
                  />
                </button>
              </div>
            </form> */}
            <div className="lgt">
              <Tab />
              {/* <div className="lgt-text">Nitish Jain</div>{" "} */}
              <Dropdown
                menu={{
                  items,
                  selectable: true,
                  defaultActiveFirst: 1,
                  onClick,
                }}
                className="lgt-icon"
                trigger={["click"]}
                // onClick={onLogout}
              >
                <Typography.Link>
                  <Space>
                    <img src={UserIcon} alt="" className="lgt-btn" />{" "}
                  </Space>
                </Typography.Link>
              </Dropdown>
              {/* <div className="lgt-text">User Profile</div>
            <img src={UserIcon} alt="" className="lgt-btn" /> */}
            </div>
          </div>
        )}
        {location.pathname !== "/" &&
          location.search !== "?fetchSize=20&pageNo=0" &&
          location.search !== "?pageNo=0&fetchSize=20" &&
          hideback === true && (
            <div className="bck-btn">
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() => {
                  location.pathname === "/tabs/music"
                    ? navigate("/tabs/brands")
                    : navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />
              <div>Back</div>
            </div>
          )}
        <Routes>
          <Route path="/" Component={Home}></Route>
          <Route path="/login" Component={Login} />
          <Route
            path="/tabs/brands"
            element={<BrandsTab setHideback={setHideback} />}
          />
          <Route
            path="/tabs/music"
            element={<MusicTab setHideback={setHideback} />}
          />
          <Route path="/tabs/dashboard" element={<Dashboard />} />
          <Route
            path="/tabs/report"
            element={<CompanyReport setHideback={setHideback} />}
          />
          <Route
            path="/brands/suggestedMusic"
            element={
              <AuthRoute>
                <SuggestedMusic />
              </AuthRoute>
            }
          />
          <Route
            path="/brandDetails"
            element={
              <AuthRoute>
                <BrandDetails />
              </AuthRoute>
            }
          />
          <Route
            path="/tabs/brands/brandInfo"
            element={<BrandInfo></BrandInfo>}
          />
          <Route
            path="/tabs/brands/generatemail"
            element={<GenerateMail></GenerateMail>}
          />

          {/* <Route path="/tabs" Component={Tab} />
          <Route path="/brandDetails" Component={BrandDetails} />
          */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
