const reportDataSuccess = (payload) => ({
  type: "REPORTDATA_SUCCESS",
  payload,
});

const reportDataFailure = (error) => ({
  type: "REPORTDATA_FAILURE",
  error,
});

const resetreportDataFlag = () => ({ type: "REPORTDATA_RESET" });

const reportDataCall = () => ({
  type: "REPORTDATA_API_CALL",
});
export {
  reportDataSuccess,
  reportDataFailure,
  resetreportDataFlag,
  reportDataCall,
};
