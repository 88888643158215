const generatedRelatedMusicSuccess = (payload) => ({
  type: "GENERATE_RELATED_MUSIC_SUCCESS",
  payload,
});

const generatedRelatedMusicFailure = (error) => ({
  type: "GENERATE_RELATED_MUSIC_FAILURE",
  error,
});

const resetgeneratedRelatedMusicFlag = () => ({
  type: "GENERATE_RELATED_MUSIC_RESET",
});

const generatedRelatedMusicCall = () => ({
  type: "GENERATE_RELATED_MUSIC_API_CALL",
});
export {
  generatedRelatedMusicCall,
  generatedRelatedMusicSuccess,
  generatedRelatedMusicFailure,
  resetgeneratedRelatedMusicFlag,
};
