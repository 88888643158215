const defaultState = {
  loading: false,
  isError: false,
  error: {},
  flag: false,
};

const apiReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "BEGIN":
      return {
        ...state,
        loading: true,
        flag: false,
        error: {},
      };
    case "SUCCESS":
      return {
        ...state,
        loading: false,
        isError: false,
        flag: true,
      };
    case "FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "RESET":
      return {
        ...defaultState,
      };
    default:
      return { ...state };
  }
};

export const apiDataReducer = {
  apiStatus: apiReducer,
};
