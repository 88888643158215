import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  reportDataCall,
  reportDataSuccess,
  reportDataFailure,
} from "../action/reportDataAction";
import { apiConfig } from "../apiConfig/reportDataApiConfig";
export const getreportCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.REPORTDATA };
  apiPayload.url += `?selectionBy=${key}`;
  dispatch(apiBegin());
  dispatch(reportDataCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(reportDataSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(reportDataFailure(err));
      dispatch(apiFailure(err));
    });
};
