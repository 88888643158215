import { combineReducers } from "redux";
import { apiDataReducer } from "../reducer/apiReducer";
import { loginDataReducer } from "./loginReducer";
import { getbrandsDataReducers } from "./brandsDataReducers";
import { getbrandsDataDetailsReducer } from "./brandsDetailsDataReducer";
import { getgeneratedRelatedMusicDataReducer } from "./generateMusicDataReducer";
import { getmusicsDataReducers } from "./musicsDataReducer";
import { getviewAllRelatedMusicDataReducer } from "./viewAllRelatedMusicReducer";
import { getbrandsDataCountReducers } from "./brandsDataCountReducer";
import { getmusicsDataCountReducers } from "./musicsDataCountReducer";
import { getallRelatedMusicsDataCountReducers } from "./viewAllRelatedMusicCountReducer";
import { getfiltersReducers } from "./brandsfiltersReducer";
import { getMusicFiltersReducers } from "./musicFiltersReducer";
import { getemailStatsReducers } from "./emailStatsReducer";
import { getdashboardDataReducers } from "./dashboardDataReducer";
import { getreportDataReducers } from "./reportDataReducer";
import { getcsvDownloadReducers } from "./csvDownloadReducer";
export default combineReducers({
  ...apiDataReducer,
  ...loginDataReducer,
  ...getbrandsDataReducers,
  ...getbrandsDataDetailsReducer,
  ...getgeneratedRelatedMusicDataReducer,
  ...getmusicsDataReducers,
  ...getviewAllRelatedMusicDataReducer,
  ...getbrandsDataCountReducers,
  ...getmusicsDataCountReducers,
  ...getallRelatedMusicsDataCountReducers,
  ...getfiltersReducers,
  ...getMusicFiltersReducers,
  ...getemailStatsReducers,
  ...getdashboardDataReducers,
  ...getreportDataReducers,
  ...getcsvDownloadReducers,
});
