import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./brandDetails.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const BrandDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <Button onClick={() => setModalOpen(true)} className="lgn-btn">
        <label className="text">Log In</label>
        <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
      </Button>{" "}
      <Modal
        title={
          <>
            <div className="title">Login</div>
          </>
        }
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          // onFinish={onLogin}
          autoComplete="off"
          layout="vertical"
          className="modal-form"
          // form={formRef}
        >
          {/* <Form.Item
              label="Brand Unique ID"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Write your brand unique Id here",
                  type: "email",
                },
              ]}
            >
              <Input
                size="large"
                className="modal-login-input"
                placeholder="Write your brand unique Id here"
              />
            </Form.Item> */}
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Enter your email here",
                type: "username",
              },
            ]}
          >
            <Input
              size="large"
              className="modal-login-input"
              placeholder="Enter your email here"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                type: "regexp",
                pattern: new RegExp(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
                ),
                message: "Please enter your password!",
              },
            ]}
          >
            <Input.Password
              size="large"
              className="modal-login-input"
              placeholder="**********"
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              className="modal-login-btn"
              onClick={() => navigate("/tabs")}
            >
              Login
            </Button>
          </Form.Item>

          {/* <Row
              className="row"
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              Don't have acoount ?? &nbsp;
              <Link className="links" style={{ color: "blue" }}>
                {" "}
                Sign Up{" "}
              </Link>
            </Row> */}
        </Form>
      </Modal>
    </div>
  );
};

export default BrandDetails;
