/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import "./generateemail.scss";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import send from "../../assets/send.svg";
import read from "../../assets/read.svg";
import righttick from "../../assets/righttick.svg";
import unsend from "../../assets/unsend.svg";
import xmark from "../../assets/xmark.svg";
import { useDispatch, useSelector } from "react-redux";
import { getEmailStats } from "../../redux/apiCall/getEmailStatsApi";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const GenerateMail = () => {
  // const check = useSelector((state) => state?.data);
  const { state } = useLocation();
  const [openMails, setOpenMails] = useState(false);
  const [openMailsView, setOpenMailsView] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const { data: emailStats, loading: emailStatsLoading } = useSelector(
    (state) => state?.emailStats
  );
  console?.log(emailStats);
  const [viewHistory, setViewHistory] = useState([]);
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    queryParam.set("title", state?.company);
    dispatch(getEmailStats(queryParam));
  }, []);

  return (
    <div className="generate-mail-container">
      <Modal
        centered
        open={openMails}
        onCancel={() => setOpenMails(false)}
        width={""}
        footer={null}
        className="models"
      >
        {!!emailStatsLoading ? (
          <FontAwesomeIcon
            icon={faSpinner}
            style={{ color: "black", height: "3vw", marginLeft: "5vw" }}
            spin
          />
        ) : emailStats.length > 0 ? (
          // {demoEmails.length > 0 ? (
          <table className="table-content">
            <tr className="heading-row">
              <th>Name</th>
              <th>Designation</th>
              <th>Email ID</th>
              <th>Mail Send</th>
              <th>View Status</th>
            </tr>
            {emailStats?.map((e, idx) => {
              return (
                <>
                  <tr>
                    <td className="name">{e?.name}</td>
                    <td className="designation">{e?.designation}</td>
                    <td className="emailid">{e?.email} </td>
                    <td className="mailsend">
                      {e?.mailSendStatus === "true" ? (
                        <div style={{ display: "flex", gap: "1.5vw" }}>
                          <img src={send}></img> <img src={xmark}></img>
                        </div>
                      ) : (
                        <div style={{ display: "flex", gap: "1.5vw" }}>
                          <img src={righttick}></img>
                          <img src={unsend}></img>
                        </div>
                      )}

                      {/* <div className="status">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="status"><FontAwesomeIcon icon={faXmark} /></div> */}
                    </td>
                    <td className="viewstatus">
                      <div className="container">
                        <img src={read} alt="" />
                        <div className="data">
                          <div className="label">Mail Visibility</div>
                          <div className="clicked-label">
                            {" "}
                            Clicked {e?.mailViewCount} times
                          </div>
                          <div className="values">
                            {e?.emailOpenTime?.length > 0
                              ? e?.emailOpenTime?.map((val) => {
                                  return <div>{val}</div>;
                                })
                              : "-"}
                          </div>
                          <div className="label">Calendly Link</div>
                          <div className="clicked-label">
                            Clicked {e?.calendlyLinkClickCount} times
                          </div>

                          <div className="values">
                            {e?.calendlyLinkClickTime?.length > 0
                              ? e?.calendlyLinkClickTime?.map((val) => {
                                  return <div>{val}</div>;
                                })
                              : "-"}
                          </div>
                          <div className="label">Youtube Link</div>
                          <div className="clicked-label">
                            Clicked {e?.youtubeLinkClickCount} times
                          </div>

                          <div className="values">
                            {e?.youtubeLinkClickTime?.length > 0
                              ? e?.youtubeLinkClickTime?.map((val) => {
                                  return <div>{val}</div>;
                                })
                              : "-"}
                          </div>
                          <div className="label">Website Link</div>
                          <div className="values">-</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        ) : (
          "No Data Found"
        )}
      </Modal>
      <Modal
        centered
        // mask
        open={openMailsView}
        onCancel={() => setOpenMailsView(false)}
        width={200}
        footer={null}
        className="models"
        style={{ backgroundColor: "#D9D9D9" }}
      >
        {console?.log(viewHistory, "viewhistory in models")}
        {/* {viewHistory &&
          viewHistory?.map((ele) => {
            return (
              <div>
                <div className="contents">
                  <div className="label">Mail Visibility</div>
                  {ele?.mailTrackingStatus}
                </div>
                <div className="contents">
                <div className="label">Calendly link</div>-{" "}
              </div>
              <div className="contents">
                <div className="label">Youtube Link</div>-
              </div>
              <div className="contents">
                <div className="label">Website Link</div>-
              </div>
              </div>
            );
          })} */}
      </Modal>
      <div className="title">{state?.company}</div>

      <div className="generate-mail-container-details">
        <pre className="details">{state?.data}</pre>
      </div>
      <div className="button">
        <button className="button-details" onClick={() => setOpenMails(true)}>
          List Of People
        </button>
        <button
          className="button-details"
          onClick={() => {
            navigator.clipboard.writeText(state?.data);
            toast("Email Copied");
          }}
        >
          Copy Email
        </button>
      </div>
    </div>
  );
};

export default GenerateMail;
