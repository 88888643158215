import apiClient from "../../utils/apiClient";
import { apiFailure, apiSuccess } from "../action/apiAction";
import {
  brandsDataDetailsCall,
  brandsDataDetailsSuccess,
  brandsDataDetailsFailure,
} from "../action/brandsDetailsAction";
import { apiConfig } from "../apiConfig/brandsDataApiConfig";
const getbrandsDetailCall = (companyName) => (dispatch) => {
  let apiPayload = { ...apiConfig.BRANDSDATA_DETAILS };

  apiPayload.url += `?companyName=${companyName}`;

  dispatch(brandsDataDetailsCall());
  dispatch(brandsDataDetailsCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(brandsDataDetailsSuccess(res.data.data));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(brandsDataDetailsFailure(err));
      dispatch(apiFailure(err));
    });
};
export default getbrandsDetailCall;
