import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  filtersCall,
  filtersSuccess,
  filtersFailure,
} from "../action/filtersAction";
import { apiConfig } from "../apiConfig/brandsDataApiConfig";
export const getBrandsFiltersCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.BRANDS_FILTERS_DATA };
  apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(filtersCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(filtersSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(filtersFailure(err));
      dispatch(apiFailure(err));
    });
};
