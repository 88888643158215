const defaultState = {
  loading: false,
  isError: false,
  error: {},
  flag: false,
  data: [],
};

const musicsDataCountReducers = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "MUSICSDATA_COUNT_SUCCESS":
      return {
        ...state,
        loading: false,
        isError: false,
        flag: true,
        data: action.payload,
      };
    case "MUSICSDATA_COUNT_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "MUSICSDATA_COUNT_RESET":
      return {
        ...defaultState,
      };
    case "MUSICSDATA_COUNT_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: {},
      };
    default:
      return { ...state };
  }
};
export const getmusicsDataCountReducers = {
  musicsDataCount: musicsDataCountReducers,
};
