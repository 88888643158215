export const apiConfig = {
  MUSICSDATA: {
    method: "GET",
    url: "/musicList",
    data: {},
  },
  MUSICSDATA_COUNT: {
    method: "GET",
    url: "/musicCount",
    data: {},
  },
  VIEW_ALL_MUSIC_DATA: {
    method: "GET",
    url: "/getRelatedMusic",
    data: {},
  },
  VIEW_ALL_MUSIC_DATA_COUNT: {
    method: "GET",
    url: "/getRelatedMusicCount",
    data: {},
  },
  MUSIC_FILTERS: {
    method: "GET",
    url: "/brand/getAvatarList",
    data: {},
  },
};
