import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  brandsDataCountCall,
  brandsDataCountSuccess,
  brandsDataCountFailure,
} from "../action/brandsDataCountAction";
import { apiConfig } from "../apiConfig/brandsDataApiConfig";
export const getbrandsCountCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.BRANDSDATA_COUNT };
  apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(brandsDataCountCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(brandsDataCountSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(brandsDataCountFailure(err));
      dispatch(apiFailure(err));
    });
};
