import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  brandsDataCall,
  brandsDataSuccess,
  brandsDataFailure,
} from "../action/brandsDataAction";
import { apiConfig } from "../apiConfig/brandsDataApiConfig";
export const getbrandsCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.BRANDSDATA };
  apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(brandsDataCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(brandsDataSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(brandsDataFailure(err));
      dispatch(apiFailure(err));
    });
};
