const brandsDataDetailsSuccess = (payload) => ({
  type: "BRANDS_DATA_DETAILS_SUCCESS",
  payload,
});

const brandsDataDetailsFailure = (error) => ({
  type: "BRANDS_DATA_DETAILS_FAILURE",
  error,
});

const resetbrandsDataDetailsFlag = () => ({
  type: "BRANDS_DATA_DETAILS_RESET",
});

const brandsDataDetailsCall = () => ({
  type: "BRANDS_DATA_DETAILS_API_CALL",
});
export {
  brandsDataDetailsCall,
  brandsDataDetailsSuccess,
  brandsDataDetailsFailure,
  resetbrandsDataDetailsFlag,
};
