import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  dashboardDataCall,
  dashboardDataSuccess,
  dashboardDataFailure,
} from "../action/dashboardAction";
import { apiConfig } from "../apiConfig/dashboardDataApiConfig";
export const getdashboardCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.DASHBOARD };
  apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(dashboardDataCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(dashboardDataSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(dashboardDataFailure(err));
      dispatch(apiFailure(err));
    });
};
