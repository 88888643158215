export const apiConfig = {
  REPORTDATA: {
    method: "GET",
    url: "/companyReport/details",
    data: {},
  },
  CSVDOWNLOAD: {
    method: "GET",
    url: "/companyReport/csvReport",
    data: {},
  },
};
