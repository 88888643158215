export const apiConfig = {
  BRANDSDATA: {
    method: "GET",
    url: "/companyList",
    data: {},
  },
  BRANDSDATA_COUNT: {
    method: "GET",
    url: "/companyCount",
    data: {},
  },
  BRANDSDATA_DETAILS: {
    method: "GET",
    url: "/companyDetails",
    data: {},
  },
  GENERATE_MUSIC_DATA: {
    method: "GET",
    url: "/companyRelatedMusic",
    data: {},
  },
  BRANDS_FILTERS_DATA: {
    method: "GET",
    url: "/brand/getSectorAndGeographiccalArea",
    data: {},
  },
  EMAIL_STATS: {
    method: "GET",
    url: "/brands/generateEmail",
    data: {},
  },
};
