// import { Tabs } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./tabs.scss";
import { Tabs } from "antd";
import { useEffect } from "react";
const Tab = () => {
  // const notify = () => toast("Its Working");
  const location = useLocation();
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: `Brands`,
      path: "/tabs/brands",
      routeName: "brands",
    },
    {
      key: "2",
      label: `Music`,
      path: "/tabs/music",
      routeName: "music",
    },
    {
      key: "3",
      label: `Dashboard`,
      path: "/tabs/dashboard",
      routeName: "dashboard",
    },
    {
      key: "4",
      label: `Company Report`,
      path: "/tabs/report",
      routeName: "report",
    },
  ];
  const [activeKey, setActiveKey] = useState(
    location.pathname.includes("/tabs/brands" || "/tabs/brands/suggestedMusic")
      ? "1"
      : location.pathname.includes("/tabs/music")
      ? "2"
      : location.pathname.includes("/dashboard")
      ? "3"
      : "4"
  );

  const onChange = (key) => {
    const activeTab = items.find((item) => item.key === key);
    setActiveKey(key);
    navigate(`${activeTab?.path}`);
  };

  useEffect(() => {
    setActiveKey(
      location.pathname.includes(
        "/tabs/brands" || "/tabs/brands/suggestedMusic"
      )
        ? "1"
        : location.pathname.includes("/tabs/music")
        ? "2"
        : location.pathname.includes("/dashboard")
        ? "3"
        : "4"
    );
  }, [location.pathname]);

  // let key = "1";
  const queryParam = new URLSearchParams(location.search);
  // location.pathname === "/tabs/music" ? (key = "2") : (key = "1");

  return (
    <div className="tabs">
      <Tabs
        defaultActiveKey={"1"}
        activeKey={activeKey}
        items={items}
        onChange={onChange}
        className="tabs-open"
      ></Tabs>
    </div>
  );
};
export default Tab;
