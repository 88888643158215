const filtersSuccess = (payload) => ({
  type: "FILTERS_SUCCESS",
  payload,
});

const filtersFailure = (error) => ({
  type: "FILTERS_FAILURE",
  error,
});

const resetfiltersFlag = () => ({ type: "FILTERS_RESET" });

const filtersCall = () => ({
  type: "FILTERS_API_CALL",
});
export { filtersSuccess, filtersFailure, resetfiltersFlag, filtersCall };
