import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  musicFiltersCall,
  musicFiltersSuccess,
  musicFiltersFailure,
} from "../action/musicFiltersAction";
import { apiConfig } from "../apiConfig/musicsDataApiConfig";
export const getmusicFiltersCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.MUSIC_FILTERS };
//   apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(musicFiltersCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(musicFiltersSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(musicFiltersFailure(err));
      dispatch(apiFailure(err));
    });
};
