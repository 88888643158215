import React from "react";
import ReactApexChart from "react-apexcharts";
function formatDate(inputDate) {
  const formattedDate = new Date(inputDate);
  const day = formattedDate.getDate().toString().padStart(2, "0");
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const year = formattedDate.getFullYear().toString().slice(-2);
  return `${day}/${month}/${year}`;
}
const LineChart = ({ graphData }) => {
  // const formattedDate = moment(inputDate, 'MMMM D, YYYY').format('DD/MM/YY');
  const formattedGraphData = graphData?.map((item) => ({
    ...item,
    eventDate: formatDate(item.eventDate),
  }));
  const eventDates = formattedGraphData?.map((item) => item.eventDate);
  const options = {
    chart: {
      height: 328,
      type: "line",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 3,
        left: 2,
        blur: 4,
        opacity: 1,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#D59F43", "#E95FFF"],

    title: {
      text: "Media",
      align: "left",
      offsetY: 25,
      offsetX: 20,
      style: {
        color: "white",
      },
    },
    subtitle: {
      text: "Statistics",
      offsetY: 55,
      offsetX: 20,
      style: {
        color: "white",
      },
    },
    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 9,
      },
      style: {
        color: "white",
      },
    },
    grid: {
      show: true,
      padding: {
        bottom: 0,
      },
      style: {
        color: "white",
      },
    },
    labels: formattedGraphData?.map((item) => item.eventDate),
    xaxis: {
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetY: -20,
    },
  };

  let series = [
    {
      name: "Email link opened by company",
      // data: emailLinkOpenedPercentage,
      data: formattedGraphData?.map((item) => item.mailViewCount),
    },
    {
      name: "Youtube video watched",
      // data: youtubeVideoWatchedPercentage,
      data: formattedGraphData?.map((item) => item.youtubeCount),
    },
    // {
    //   name: "Music",
    //   data: [1, 15, 26, 20, 33, 27],
    // },
    // {
    //   name: "Photos",
    //   data: [3, 33, 21, 42, 19, 32],
    // },
    // {
    //   name: "Files",
    //   data: [0, 39, 52, 11, 29, 43],
    // },
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={500}
        width={900}
      />
    </div>
  );
};

export default LineChart;
