import React, { useEffect, useState } from "react";
import "./brandsTab.scss";
import Cards from "../../components/cards/card.jsx";
import { Button, Modal, Pagination, Radio, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import BrandDetails from "../brandDetails/brandDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { getbrandsCall } from "../../redux/apiCall/getBrandsDataApi";
import { useDispatch, useSelector } from "react-redux";
import getbrandsDetailCall from "../../redux/apiCall/getBrandsDetailsApi";
import { getbrandsCountCall } from "../../redux/apiCall/getBrandsDataCountApi";
import { getBrandsFiltersCall } from "../../redux/apiCall/getBrandsFiltersApi";
import aplybtn from "../../assets/applybtn2.svg";

const BrandsTab = ({ setHideback }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModelOpen] = useState(false);
  const [selectedSector, setSelectedSector] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [searchText, setSearchText] = useState("");

  const { data: brandsData, loading: brandsDataLoading } = useSelector(
    (state) => state.brandsData
  );
  const { data: brandsDataCount, loading: brandsDataCountLoading } =
    useSelector((state) => state.brandsDataCount);
  const { data: brandsDataDetails, loading: brandsDataDetailsLoading } =
    useSelector((state) => state.brandsDataDetails);
  const { data: filtersData, loading: filtersLoading } = useSelector(
    (state) => state.filters
  );
  const showModel = () => {
    setModelOpen(true);
  };

  // const [value, setValue] = useState("All");
  const [value, setValue] = useState("All");
  const onChange = (e) => {
    setValue(e.target.value);
    const filterParam = new URLSearchParams(location.search);
    filterParam.delete("musicSearch");
    filterParam.set("emailStatus", e.target.value);
    filterParam.set("pageNo", 0);
    navigate(`/tabs/brands?${filterParam.toString()}`);
  };

  const brandName = brandsDataDetails?.company;
  const [companyName, setCompanyName] = useState("");
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const fetchSize = queryParam.get("fetchSize")
    ? parseInt(queryParam.get("fetchSize"))
    : parseInt(queryParam.set("fetchSize", 20), 10);
  const [currentPage, setCurrentPage] = useState(0);
  const pageNo = queryParam.get("pageNo")
    ? parseInt(queryParam.get("pageNo"))
    : parseInt(queryParam.set("pageNo", currentPage), 10);
  const onPageChange = (pageState) => {
    setCurrentPage(pageState - 1);
    const queryParam = new URLSearchParams(location.search);
    if (pageState.selected !== pageNo) {
      queryParam.set("pageNo", pageState - 1);
      navigate(`${location.pathname}?${queryParam}`);
      dispatch(getbrandsCall(queryParam));
    }
  };

  const onCardClick = (ele) => {
    queryParam.set("avtar", ele.avatar);
    dispatch(getbrandsDetailCall(ele.company));
    setCompanyName(ele.company);
    const param = new URLSearchParams(location.search);
    param.delete("fetchSize");
    param.delete("pageNo");
    param.delete("search");
    param.set("companyName", ele.company);
    navigate(`/tabs/brands/brandinfo?${param.toString()}`);
  };

  useEffect(() => {
    queryParam.delete("musicSearch");
    setValue(queryParam.get("emailStatus") || "All");
    navigate(`/tabs/brands?${queryParam.toString()}`);
  }, []);
  // useEffect(() => {
  //   const filterParam=new URLSearchParams(location.search);
  //   filterParam.delete("musicSearch");
  //   // filterParam.set("emailStatus", value);
  //   filterParam .set("pageNo", 0);
  //   navigate(`/tabs/brands?${filterParam.toString()}`);
  // }, [value]);
  useEffect(() => {
    queryParam.delete("musicSearch");
    // queryParam.set("emailStatus", value);
    dispatch(getbrandsCall(queryParam));
    dispatch(getbrandsCountCall(queryParam));
    dispatch(getBrandsFiltersCall());
  }, [location.search]);

  useEffect(() => {
    setHideback(false);
    return () => {
      setHideback(true);
    };
  }, []);

  const SearchFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sectorRequested", selectedSector.toString());
    searchParams.set("areaRequested", selectedArea.toString());

    dispatch(getbrandsCall(searchParams));
    dispatch(getbrandsCountCall(searchParams));
    searchParams.set("pageNo", 0);
    navigate(`/tabs/brands?${searchParams}`);
  };

  const renderCards = () => {
    return brandsData?.map((ele, idx) => (
      <Cards
        avtar={ele.dominant || "-"}
        brandName={ele.company || "-"}
        navrasa={ele.sector || "-"}
        onclick={() => onCardClick(ele)}
      >
        <BrandDetails onClick={showModel} isShow={modalOpen}></BrandDetails>
      </Cards>
    ));
  };

  // // // 14/09
  // const renderCards = () => {
  //   return brandsData
  //     .filter((ele) => {
  //       if (value === "Sent") {
  //         return ele.emailStatus === "sent";
  //       } else if (value === "Unsend") {
  //         return ele.emailStatus === "unsent";
  //       } else {
  //         return true;
  //       }
  //     })
  //     .map((ele, idx) => (
  //       <Cards
  //         avtar={ele.dominant || "-"}
  //         brandName={ele.company || "-"}
  //         navrasa={ele.sector || "-"}
  //         onclick={() => onCardClick(ele)}
  //       >
  //         <BrandDetails onClick={showModel} isShow={modalOpen}></BrandDetails>
  //       </Cards>
  //     ));
  // };

  const showDetails = () => {
    return (
      <div>
        <div className="details-show">
          <div className="details-name">Brand Avatar</div>
          <div className="details-value">
            <ul>
              <li>
                <label className="label">Dominant - </label>
                {brandsDataDetails.dominant || "-"}
              </li>
              <li>
                <label className="label">Sub Dominant - </label>
                {brandsDataDetails.subdominant || "-"}
              </li>
            </ul>
          </div>
        </div>
        <div className="details-show">
          <div className="details-name">Brand Sector</div>
          <div className="details-value">{brandsDataDetails.sector || "-"}</div>
        </div>
        <div className="details-show">
          <div className="details-name">Brand Geographical Area</div>
          <div className="details-value">
            {brandsDataDetails.geographicalArea || "-"}
          </div>
        </div>
        <div className="details-show">
          <div className="details-name">Brand Value</div>
          <div className="details-value">{brandsDataDetails.values || "-"}</div>
        </div>
        <div className="details-show">
          <div className="details-name">Brand Mission</div>
          <div className="details-value">
            {brandsDataDetails.mission || "-"}
          </div>
        </div>
        <div className="details-show">
          <div className="details-name">Brand Vision</div>
          <div className="details-value">{brandsDataDetails.vision || "-"}</div>
        </div>
        <div className="details-show">
          <div className="details-name">Logo Colour</div>
          <div className="details-value">
            {brandsDataDetails.logocolour || "-"}
          </div>
        </div>
        <div className="details-show">
          <div className="details-name">Brand Website</div>
          <div className="details-value">
            <a
              href={brandsDataDetails.companyWebsite}
              target="_blank"
              rel="noreferrer"
            >
              {brandsDataDetails.companyWebsite || "-"}
            </a>
          </div>
        </div>
      </div>
    );
  };
  const onGenerateMusic = () => {
    queryParam.set("companyName", companyName);
    navigate(`/brands/suggestedMusic?${queryParam}`);
  };

  const onSearch = (e) => {
    setSearchText(e);
  };

  return (
    <div>
      <div className="brand-tab">
        <div className="heading">
          <div className="title">Brands</div>

          {/* <form
            autoComplete="on"
            typeof="submit"
            onSubmit={(e) => onSubmitData(e)}
          >
            <div className="search-box">
              <AutoComplete
                placeholder="Search Brand"
                className="input-box"
                onChange={(e) => setSearchText(e)}
                value={searchText}

                // onSearch={onSearch}
              ></AutoComplete>
              <button className="search-btn" type="submit">
                <FontAwesomeIcon
                  className="search-icon"
                  icon={faMagnifyingGlass}
                  // type="submit"
                />
              </button>
            </div>
          </form> */}
          {/* <Input
          className="input-box"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.value);
          }}
          placeholder="Search Brand"
          // onFocus={(e) => {
          //   setSearchText("");
          // }}
        /> */}
          <div className="filters">
            <Select
              mode="multiple"
              placeholder="Sectors"
              onChange={setSelectedSector}
              options={filtersData?.sectorList?.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <Select
              mode="multiple"
              placeholder="Geographical Area"
              onChange={setSelectedArea}
              options={filtersData?.areaList?.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <img
              src={aplybtn}
              alt=""
              className="aplybtn"
              onClick={() => {
                SearchFilters();
              }}
            ></img>
          </div>
        </div>
        <div className="filter-mail">
          {/* Email :{" "} */}{" "}
          <Radio.Group
            onChange={onChange}
            defaultValue={"All"}
            value={value}
            className="radioButtons"
          >
            {/* <Radio value={"All"}>All</Radio>
            <Radio value={"sent"}>Sent</Radio>
            <Radio value={"unsent"}>Unsent</Radio> */}

            {/*  */}
            <Radio value={"All"}>All Email</Radio>
            <Radio value={"sent"}>Sent Email</Radio>
            <Radio value={"unsent"}>Unsent Email</Radio>
          </Radio.Group>
        </div>
        <div className="renderCards-and-pagination">
          <div className="renderCards">{renderCards()}</div>
          <Modal
            title={
              <div className="header-set">
                <div className="brand-title">{brandName}</div>
                <div className="close-btn" onClick={() => setModelOpen(false)}>
                  <FontAwesomeIcon icon={faCircleXmark} />
                </div>
              </div>
            }
            centered
            open={modalOpen}
            onOk={() => setModelOpen(false)}
            onCancel={() => setModelOpen(false)}
            footer={null}
            style={{ border: "none" }}
            className="brand-model"
          >
            <div className="details">{brandName && showDetails()}</div>
            <div className="btn">
              <Button className="genrt-btn" onClick={onGenerateMusic}>
                <label className="text">Generate Music</label>
              </Button>
            </div>
          </Modal>

          {brandsDataCount.total !== 0 && (
            <Pagination
              total={brandsDataCount.total}
              defaultCurrent={pageNo + 1}
              current={pageNo + 1}
              pageSize={fetchSize || 20}
              onChange={onPageChange}
              showQuickJumper={false}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandsTab;
