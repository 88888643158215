import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  musicsDataCountCall,
  musicsDataCountSuccess,
  musicsDataCountFailure,
} from "../action/musicDataCountAction";
import { apiConfig } from "../apiConfig/musicsDataApiConfig";
export const getmusicsCountCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.MUSICSDATA_COUNT };
  apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(musicsDataCountCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(musicsDataCountSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(musicsDataCountFailure(err));
      dispatch(apiFailure(err));
    });
};
