const defaultState = {
  loading: false,
  isError: false,
  error: {},
  flag: false,
  data: {},
};

const brandsDataDetailsReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "BRANDS_DATA_DETAILS_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: {},
      };
    case "BRANDS_DATA_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        isError: false,
        flag: true,
        data: action.payload,
      };
    case "BRANDS_DATA_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "BRANDS_DATA_DETAILS_RESET":
      return {
        ...defaultState,
      };
    default:
      return { ...state };
  }
};
export const getbrandsDataDetailsReducer = {
  brandsDataDetails: brandsDataDetailsReducer,
};
