import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  generatedRelatedMusicCall,
  generatedRelatedMusicSuccess,
  generatedRelatedMusicFailure,
} from "../action/generateMusicAction";
import { apiConfig } from "../apiConfig/brandsDataApiConfig";
export const getgeneratedRelatedMusicCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.GENERATE_MUSIC_DATA };
  apiPayload.url += `?companyName=${key}`;
  dispatch(apiBegin());
  dispatch(generatedRelatedMusicCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(generatedRelatedMusicSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(generatedRelatedMusicFailure(err));
      dispatch(apiFailure(err));
    });
};
