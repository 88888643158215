const brandsDataCountSuccess = (payload) => ({
  type: "BRANDSDATA_COUNT_SUCCESS",
  payload,
});

const brandsDataCountFailure = (error) => ({
  type: "BRANDSDATA_COUNT_FAILURE",
  error,
});

const resetbrandsDataCountFlag = () => ({ type: "BRANDSDATA_COUNT_RESET" });

const brandsDataCountCall = () => ({
  type: "BRANDSDATA_COUNT_API_CALL",
});
export {
  brandsDataCountSuccess,
  brandsDataCountFailure,
  resetbrandsDataCountFlag,
  brandsDataCountCall,
};
