import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  musicsDataCall,
  musicsDataSuccess,
  musicsDataFailure,
} from "../action/musicsDataAction";
import { apiConfig } from "../apiConfig/musicsDataApiConfig";
export const getmusicsCall = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.MUSICSDATA };
  apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(musicsDataCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(musicsDataSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(musicsDataFailure(err));
      dispatch(apiFailure(err));
    });
};
