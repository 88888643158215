import React, { useEffect, useState } from "react";
import "./brandinfo.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getbrandsDetailCall from "../../redux/apiCall/getBrandsDetailsApi";
import { Modal } from "antd";
const BrandInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: brandsDataDetails, loading: brandsDataDetailsLoading } =
    useSelector((state) => state.brandsDataDetails);
  let company = "";
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    company = queryParam.get("companyName");
    dispatch(getbrandsDetailCall(company));
  }, []);
  const onGenerateMusic = () => {
    // dispatch(getgenerateMusicCall(avtar));
    const queryParam = new URLSearchParams(location.search);

    queryParam.set("companyName", queryParam.get("companyName"));
    navigate(`/brands/suggestedMusic?${queryParam}`);
  };
  const onGenerateMail = () => {
    navigate("/tabs/brands/generatemail", {
      state: {
        data: brandsDataDetails.emailContent,
        company: brandsDataDetails.company,
      },
    });
  };

  return (
    <div className="brandinfo-container">
      <Modal
        centered
        open={open}
        onCancel={() => setOpen(false)}
        width={""}
        footer={null}
      >
        {brandsDataDetails?.wordCloudUrl !== "" ? (
          <img
            className="wordcloud-img"
            src={brandsDataDetails?.wordCloudUrl}
            alt=""
          />
        ) : (
          <h1>No Wordcloud Found</h1>
        )}
      </Modal>
      <div className="brand-value-container">
        <div className="brand-value-title">Company Name</div>
        <div className="brand-value-info">
          {brandsDataDetails.company || "-"}
        </div>
      </div>
      <div className="brand-value-container">
        <div className="brand-value-title">Brand Sector</div>
        <div className="brand-value-info">
          {brandsDataDetails.sector || "-"}
        </div>
      </div>
      <div className="brand-value-container">
        <div className="brand-value-title">Brand Geographical Area</div>
        <div className="brand-value-info">
          {brandsDataDetails.geographicalArea || "-"}
        </div>
      </div>
      <div className="brand-value-container">
        <div className="brand-value-title">Brand Value</div>
        <pre className="brand-value-info">
          {brandsDataDetails.values || "-"}
        </pre>
      </div>
      <div className="brand-value-container">
        <div className="brand-value-title">Brand Mission</div>
        <pre className="brand-value-info">
          {brandsDataDetails.mission || "-"}
        </pre>
      </div>{" "}
      <div className="brand-value-container">
        <div className="brand-value-title">Brand Vision</div>
        <pre className="brand-value-info">
          {brandsDataDetails.vision || "-"}
        </pre>
      </div>{" "}
      <div className="brand-value-container">
        <div className="brand-value-title">Brand Avatar</div>
        <div className="brand-value-info">
          <ul>
            <li>
              {" "}
              <label className="label">Dominant - </label>
              {brandsDataDetails.dominant || "-"}{" "}
            </li>
            <li>
              {" "}
              <label className="label">Sub Dominant - </label>
              {brandsDataDetails.subdominant || "-"}{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="brand-value-container">
        <div className="brand-value-title">Logo Colour</div>
        <div className="brand-value-info">
          {brandsDataDetails.logocolour || "-"}
        </div>
      </div>
      <div className="brand-value-container">
        <div className="brand-value-title">Brand Website</div>
        <div className="brand-value-info">
          {brandsDataDetails && brandsDataDetails.companyWebsite ? (
            <a
              href={
                brandsDataDetails.companyWebsite.includes("https://www.")
                  ? `${brandsDataDetails.companyWebsite}`
                  : `https://www.${brandsDataDetails.companyWebsite}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {brandsDataDetails.companyWebsite}
            </a>
          ) : (
            <span>Website not available</span>
          )}
        </div>
      </div>
      <div className="button">
        <button className="button-details" onClick={onGenerateMusic}>
          Generate Music
        </button>
        <button className="button-details" onClick={onGenerateMail}>
          Generate Email
        </button>
        <button className="button-details" onClick={() => setOpen(true)}>
          Generate Word Cloud
        </button>
      </div>
    </div>
  );
};

export default BrandInfo;
