const musicsDataCountSuccess = (payload) => ({
  type: "MUSICSDATA_COUNT_SUCCESS",
  payload,
});

const musicsDataCountFailure = (error) => ({
  type: "MUSICSDATA_COUNT_FAILURE",
  error,
});

const resetmusicsDataCountFlag = () => ({ type: "MUSICSDATA_COUNT_RESET" });

const musicsDataCountCall = () => ({
  type: "MUSICSDATA_COUNT_API_CALL",
});
export {
  musicsDataCountSuccess,
  musicsDataCountFailure,
  resetmusicsDataCountFlag,
  musicsDataCountCall,
};
