import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./login.scss";
import { useDispatch, useSelector } from "react-redux";
import { startLogin } from "../../redux/apiCall/loginApi";

const Login = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const loginUser = (data) => {
    dispatch(startLogin(data));
  };
  const { flag: isUserLoggedIn } = useSelector((state) => state.loginDetails);

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/tabs/brands?pageNo=0");
    }
  }, [isUserLoggedIn, navigate]);
  return (
    <div>
      <Button onClick={() => setModalOpen(true)} className="lgn-btn">
        <label className="text">Log In</label>
        <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
      </Button>{" "}
      <Modal
        className="login-model"
        title={
          <>
            <div className="title">Log In</div>
          </>
        }
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
        style={
          {
            // minWidth: "35vw",
            // border: "groove 10px",
            // borderRadius: "30px 30px 30px 30px",
          }
        }
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={loginUser}
          autoComplete="off"
          layout="vertical"
          className="modal-form"
          form={form}
        >
          {/* <Form.Item
            label="Brand Unique ID"
            name="email"
            rules={[
              {
                required: true,
                message: "Write your brand unique Id here",
                type: "email",
              },
            ]}
          >
            <Input
              size="large"
              className="modal-login-input"
              placeholder="Write your brand unique Id here"
            />
          </Form.Item> */}
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Enter your email here",
                type: "email",
              },
            ]}
          >
            <Input
              size="large"
              className="modal-login-input"
              placeholder="Enter your email here"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                type: "regexp",
                pattern: new RegExp(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
                ),
                message: "Please enter your password!",
              },
            ]}
          >
            <Input.Password
              size="large"
              className="modal-login-input"
              placeholder="**********"
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" size="large" className="modal-login-btn">
              Login
            </Button>
          </Form.Item>

          {/* <Row
            className="row"
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            Don't have acoount ?? &nbsp;
            <Link className="links" style={{ color: "blue" }}>
              {" "}
              Sign Up{" "}
            </Link>
          </Row> */}
        </Form>
      </Modal>
    </div>
  );
};

export default Login;
