import React from "react";
import "./card.scss";
const Cards = ({ brandName, avtar, navrasa, onclick }) => {
  return (
    <div className="card" onClick={onclick}>
      <div className="card-title">{brandName}</div>
      <div className="content">
        <div className="content-data">
          <div className="label">Avatar</div>
          <div className="value">{avtar}</div>
        </div>
        <div className="content-data">
          <div className="label">Sector</div>
          <div className="value">{navrasa}</div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
