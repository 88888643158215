const emailStatsSuccess = (payload) => ({
  type: "EMAIL_STATS_SUCCESS",
  payload,
});

const emailStatsFailure = (error) => ({
  type: "EMAIL_STATS_FAILURE",
  error,
});

const resetemailStatsFlag = () => ({ type: "EMAIL_STATS_RESET" });

const emailStatsCall = () => ({
  type: "EMAIL_STATS_API_CALL",
});
export {
  emailStatsSuccess,
  emailStatsFailure,
  resetemailStatsFlag,
  emailStatsCall,
};
