import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure } from "../action/apiAction";
import {
  emailStatsCall,
  emailStatsSuccess,
  emailStatsFailure,
} from "../action/emailStatsAction";
import { apiConfig } from "../apiConfig/brandsDataApiConfig";
export const getEmailStats = (key) => (dispatch) => {
  let apiPayload = { ...apiConfig.EMAIL_STATS };
  apiPayload.url += `?${key}`;
  dispatch(apiBegin());
  dispatch(emailStatsCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(emailStatsSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(emailStatsFailure(err));
      dispatch(apiFailure(err));
    });
};
