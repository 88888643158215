import React, { useEffect, useState } from "react";
import { /* AutoComplete */ Button, Pagination, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  // faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import aplybtn from "../../assets/applybtn2.svg";

import "./musicTab.scss";
import { useDispatch, useSelector } from "react-redux";
import { getmusicsCall } from "../../redux/apiCall/getMusicsDataApi";
import { getmusicsCountCall } from "../../redux/apiCall/getMusicsDataCountApi";
import axios from "axios";
import { getData } from "../../utils/storageService";
import ReactPlayer from "react-player";
import { getmusicFiltersCall } from "../../redux/apiCall/getMusicsFiltersApi";

const AVATAR = ["Hero", "Caregiver", "magician", "Ruler"];

const MusicTab = ({ setHideback }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showViewAllMusic, setShowViewAllRelatedMusic] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [text, setText] = useState("");

  const [musicFileUrls, setMusicFileUrls] = useState({});

  const { data: musicData, loading: musicLoading } = useSelector(
    (state) => state.musicsData
  );
  const { data: musicDataCount, loading: musicsDataCountLoading } = useSelector(
    (state) => state.musicsDataCount
  );
  const {
    data: allRelatedMusicDataCount,
    loading: allRelatedMusicsDataCountLoading,
  } = useSelector((state) => state.allRelatedMusicsDataCount);
  const { data: viewAllMusic, loading: viewAllMusicLoading } = useSelector(
    (state) => state.viewAllRelatedMusicData
  );
  const { data: musicList, loading: musicListLoading } = useSelector(
    (state) => state.musicFIlters
  );
  const queryParam = new URLSearchParams(location.search);
  const fetchSize = queryParam.get("fetchSize")
    ? parseInt(queryParam.get("fetchSize"))
    : parseInt(queryParam.set("fetchSize", 20), 10);
  const [currentPage, setCurrentPage] = useState(0);
  const pageNo = queryParam.get("pageNo")
    ? parseInt(queryParam.get("pageNo"))
    : parseInt(queryParam.set("pageNo", currentPage), 10);
  const onPageChange = (pageState) => {
    setCurrentPage(pageState - 1);
    const queryParam = new URLSearchParams(location.search);
    if (pageState.selected !== pageNo) {
      queryParam.set("pageNo", pageState - 1);
      navigate(`${location.pathname}?${queryParam}`);
      dispatch(getmusicsCall(queryParam));
    }
  };

  const getMusicFile = async (link) => {
    try {
      const response = await axios.get(link, {
        headers: {
          Authorization: getData("Token"),
        },
        responseType: "blob",
      });
      const audioUrl = window.URL.createObjectURL(new Blob([response.data]));
      return audioUrl;
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getmusicFiltersCall());
    queryParam.delete("search");
    queryParam.delete("viewAll");

    queryParam.get("avatar") && setSelectedItems(queryParam.get("avatar"));
    queryParam.get("musicSearch") && setText(queryParam.get("musicSearch"));
    dispatch(getmusicsCall(queryParam));
    dispatch(getmusicsCountCall(queryParam));
  }, [location.search]);
  useEffect(() => {
    // queryParam.set("musicSearch", searchText);
    queryParam.set("pageNo", 0);

    dispatch(getmusicsCall(queryParam));
    dispatch(getmusicsCountCall(queryParam));
    navigate(`/tabs/music?${queryParam.toString()}`);
  }, [searchText || searchText !== ""]);

  const [title, setTitle] = useState("");
  const viewAll = (ele) => {
    setTitle(ele.avatar);
    // dispatch(getviewAllRelatedMusicCall(ele?.avatar));
    // dispatch(getallRelatedMusicsCountCall(ele?.avatar));

    // queryParam.set("viewAll", ele.avatar);
    queryParam.set("pageNo", 0);
    queryParam.set("musicSearch", ele?.avatar);

    dispatch(getmusicsCall(queryParam));
    navigate(`/tabs/music?${queryParam.toString()}`);
  };

  useEffect(() => {
    if (musicData.length > 0) {
      musicData.map(async (ele) => {
        const url = await getMusicFile(ele.link);
        setMusicFileUrls({ ...musicFileUrls, [ele.id]: url });
      });
    }
  }, [musicData]);

  useEffect(() => {
    setHideback(false);
    return () => {
      setHideback(true);
    };
  }, []);
  const SearchFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("avatar", selectedItems.toString());

    dispatch(getmusicsCall(searchParams));
    dispatch(getmusicsCountCall(searchParams));
    searchParams.set("pageNo", 0);
    navigate(`/tabs/music?${searchParams}`);
  };

  useEffect(() => {
    viewAllMusic.length > 0 && setShowViewAllRelatedMusic(true);
  }, [viewAllMusic]);

  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const renderMusic = () => {
    const playSong = (ele) => {
      if (currentlyPlaying === ele.link) {
        setCurrentlyPlaying(null);
      } else {
        setCurrentlyPlaying(ele.link);
      }
    };
    return (
      musicFileUrls &&
      musicData?.map((ele) => {
        return (
          <div className="music-card">
            <div className="values">
              <div className="name">{ele.avatar}</div>
            </div>
            <div className="music">
              <ReactPlayer
                url={ele.link}
                height={"5vw"}
                width={!title ? "40vw" : "50vw"}
                controls
                onPlay={() => playSong(ele)}
                playing={ele.link === currentlyPlaying}
              />
            </div>
            {!title && (
              <div className="btn">
                <Button className="view-btn" onClick={() => viewAll(ele)}>
                  <label className="text">View All</label>
                </Button>
              </div>
            )}
          </div>
        );
      })
    );
  };

  const renderViewAllMusic = () => {
    const playSong = (ele) => {
      if (currentlyPlaying === ele.link) {
        setCurrentlyPlaying(null);
      } else {
        setCurrentlyPlaying(ele.link);
      }
    };
    return (
      musicFileUrls &&
      viewAllMusic?.map((ele, idx) => (
        <div className="music-card">
          <div className="values">
            <div className="name">{ele?.avatar}</div>
          </div>
          <div className="music">
            <ReactPlayer
              url={ele.link}
              height={"5vw"}
              width={"40vw"}
              controls
              onPlay={() => playSong(ele)}
              playing={ele.link === currentlyPlaying}
            />
          </div>
        </div>
      ))
    );
  };

  return (
    <div className="music-tab">
      <div className="heading">
        <div
          className="title"
          style={
            showViewAllMusic
              ? { justifyContent: "flex-end" }
              : { justifyContent: "center" }
          }
        >
          {title ? title : "Music"}

          {title && (
            <span
              className="back-btn"
              onClick={() => {
                // window.location.reload();
                setTitle("");
                navigate("/tabs/music?fetchSize=20&pageNo=0");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <div>Back To Music</div>
            </span>
          )}
        </div>

        {/* <form
          autoComplete="on"
          typeof="submit"
          onSubmit={(e) => onSubmitData(e)}
        >
          <div className="search-box">
            <AutoComplete
              placeholder="Search Avatar"
              className="input-box"
              onChange={(e) => setText(e)}
              value={text}

              // onSearch={onSearch}
            ></AutoComplete>
            <button className="search-btn" type="submit">
              <FontAwesomeIcon
                className="search-icon"
                icon={faMagnifyingGlass}
                // type="submit"
              />
            </button>
          </div>
        </form> */}
        {/* <Input
          className="input-box"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.value);
          }}
          placeholder="Search Brand"
          // onFocus={(e) => {
          //   setSearchText("");
          // }}
        /> */}
        <div className="filters">
          <Select
            mode="multiple"
            placeholder="Avatar"
            onChange={setSelectedItems}
            options={musicList?.avatarList?.map((item) => ({
              value: item,
              label: item,
            }))}
          />
          <img
            src={aplybtn}
            alt=""
            className="aplybtn"
            onClick={() => {
              SearchFilters();
            }}
          ></img>
        </div>
      </div>
      <div className="details">
        <div className="render-data">
          {/* {showViewAllMusic ? renderViewAllMusic() : renderMusic()} */}
          {musicDataCount.total > 0 ? (
            renderMusic()
          ) : (
            // <div style={{ color: "white" }}>No Data Found</div>
            <div className="no-data-found"><h2>No Data Found</h2></div>
          )}
        </div>
      </div>
      {musicDataCount.total !== 0 && (
        <Pagination
          total={
            musicDataCount.total
            // showViewAllMusic
            //   ? allRelatedMusicDataCount.total
            //   : musicDataCount.total
          }
          defaultCurrent={pageNo + 1}
          current={pageNo + 1}
          pageSize={fetchSize || 20}
          onChange={onPageChange}
          showQuickJumper={false}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default MusicTab;
