const brandsDataSuccess = (payload) => ({
  type: "BRANDSDATA_SUCCESS",
  payload,
});

const brandsDataFailure = (error) => ({
  type: "BRANDSDATA_FAILURE",
  error,
});

const resetbrandsDataFlag = () => ({ type: "BRANDSDATA_RESET" });

const brandsDataCall = () => ({
  type: "BRANDSDATA_API_CALL",
});
export {
  brandsDataSuccess,
  brandsDataFailure,
  resetbrandsDataFlag,
  brandsDataCall,
};
