import React, { useEffect, useRef, useState } from "react";
import "./companyReport.scss";
import { Col, Radio, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getreportCall } from "../../redux/apiCall/getReportDaraApi";
import downloadIcon from "../../assets/downloadIcon.svg";
import { getData } from "../../utils/storageService";
import axios from "axios";
const CompanyReport = ({ setHideback }) => {
  const [downloadtext, setDownloadText] = useState(false);
  const dispatch = useDispatch();
  const [download, setDownload] = useState(false);
  let rowSpan = 4;
  var CSVFileDownload = require("js-file-download");

  const [filter, setFilter] = useState("allCompanyData");
  const { data: reportData, loading: reportLoading } = useSelector(
    (state) => state.reportData
  );
  const { data: csvDownload, loading: csvDownloadLoading } = useSelector(
    (state) => state.csvDownload
  );

  const downloadLinkRef = useRef(null);

  useEffect(() => {
    setHideback(false);
    dispatch(getreportCall(filter));
  }, []);
  useEffect(() => {
    dispatch(getreportCall(filter));
  }, [filter]);
  const headers = {
    "Content-Type": "application/csv",
    responseType: "blob",
    Authorization: getData("Token"),
  };

  // // console.log(csvDownload);

  const downloadCSV = () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companyReport/csvReport?selectionBy=${filter}`;
    axios
      .get(url, { headers })
      .then((response) => {
        console.log("response", response);
        const blob = new File(
          [response.data],
          `${filter}.csv`,
          // filter === "allCompanyData" ? "CompanyData.csv" : "PersonsData.csv",
          {
            type: "text/csv;charset=utf-8",
          }
        );
        const formatedURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = formatedURL;
        link.download = `${filter}.csv`;
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Axios error:", error);
      });
  };
  // if (download === "true") {
  //   const blob = new File(
  //     [csvDownload],
  //     filter === "allCompanyData" ? "CompanyData.csv" : "PersonsData.csv",
  //     {
  //       type: "text/csv;charset=utf-8",
  //     }
  //   );
  //   const formatedURL = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = formatedURL;
  //   link.download =
  //     filter === "allCompanyData" ? "CompanyData.csv" : "PersonsData.csv";
  //   document.body.appendChild(link);
  // }

  // const downloadCSV = () => {
  //   dispatch(getCSVDownloadCall(filter));
  //   const blob = new File(
  //     [csvDownload],
  //     filter === "allCompanyData" ? "CompanyData.csv" : "PersonsData.csv",
  //     {
  //       type: "text/csv;charset=utf-8",
  //     }
  //   );
  //   const formatedURL = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = formatedURL;
  //   link.download =
  //     filter === "allCompanyData" ? "CompanyData.csv" : "PersonsData.csv";
  //   document.body.appendChild(link);
  // };
  filter !== "allCompanyData" ? (rowSpan = 3) : (rowSpan = 4);
  let sortedData = reportData?.sort((p1, p2) =>
    p1.youtube_calendly_add < p2.youtube_calendly_add
      ? 1
      : p1.youtube_calendly_add > p2.youtube_calendly_add
      ? -1
      : 0
  );

  const showDetails = () => {
    return (
      !!!reportLoading && (
        <div>
          <Row className="row-heading">
            <Col span={rowSpan}>Company</Col>
            {filter === "allCompanyData" ? (
              <Col span={rowSpan}>No of Persons</Col>
            ) : (
              <Col span={rowSpan}>Person Name</Col>
            )}
            {filter !== "allCompanyData" && <Col span={rowSpan}>Send Date</Col>}
            <Col span={rowSpan}>No of Views on Emails</Col>
            <Col span={rowSpan}>Youtube View</Col>
            <Col span={rowSpan}>Calendly View</Col>
            <Col span={rowSpan}>Total View on Youtube and Calendly</Col>
            {filter !== "allCompanyData" && (
              <Col span={rowSpan}>Mail Block Status</Col>
            )}
          </Row>
          <div className="scrolling">
            {reportData &&
              reportData.map((e) => {
                return (
                  <Row className="row-datasource" key={e.company_name}>
                    <Col span={rowSpan}>{e.company_name}</Col>
                    {filter === "allCompanyData" ? (
                      <Col span={rowSpan}>{e.person_count}</Col>
                    ) : (
                      <Col span={rowSpan}>{e.person_name}</Col>
                    )}
                    {filter !== "allCompanyData" && (
                      <Col span={rowSpan}>{e.send_date}</Col>
                    )}
                    <Col span={rowSpan}>{e.mailViewCount}</Col>
                    <Col span={rowSpan}>{e.youtubeViewCount}</Col>
                    <Col span={rowSpan}>{e.calendlyViewCount}</Col>
                    <Col span={rowSpan}>{e.youtube_calendly_add}</Col>
                    {filter !== "allCompanyData" && (
                      <Col span={rowSpan}>{e.mail_block_status}</Col>
                    )}
                  </Row>
                );
              })}
          </div>
        </div>
      )
    );
  };
  return (
    <div className="company-report">
      <div className="filter">
        <div>
          <label>Show : </label>

          <Radio.Group
            defaultValue={"allCompanyData"}
            // value={value}
            className="radioButtons"
          >
            {/* <Radio value={"All"}>All</Radio>
            <Radio value={"sent"}>Sent</Radio>
            <Radio value={"unsent"}>Unsent</Radio> */}
            {/*  */}
            <Radio
              value={"allCompanyData"}
              onChange={(e) => setFilter(e.target.value)}
            >
              All Company
            </Radio>
            <Radio
              value={"allPersonsData"}
              onChange={(e) => setFilter(e.target.value)}
            >
              All Persons by Company
            </Radio>
            <Radio
              value={"hotLeadPersons"}
              onChange={(e) => setFilter(e.target.value)}
            >
              Hot Lead Persons
            </Radio>
          </Radio.Group>
        </div>
        <div
          className="download"
          onMouseOver={() => setDownloadText(true)}
          onMouseOut={() => setDownloadText(false)}
          onClick={() => downloadCSV()}
        >
          {" "}
          <img src={downloadIcon} alt="download" className="download-icon" />
          {downloadtext && "Download"}
        </div>
      </div>
      <div className="table">{showDetails()}</div>
    </div>
  );
};

export default CompanyReport;
