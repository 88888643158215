import apiClient, { setHeader } from "../../utils/apiClient";
import { addSession } from "../../utils/sessionManagement";
import { apiBegin, apiFailure, apiSuccess } from "../action/apiAction";
import { loginCall, loginFailure, loginSuccess } from "../action/loginActions";
import { apiConfig } from "../apiConfig/loginApiConfig";

export const startLogin = (payload) => (dispatch) => {
  const apiPayload = { ...apiConfig.LOGIN };
  apiPayload.data.email = payload.email;
  apiPayload.data.password = payload.password;
  dispatch(apiBegin());
  dispatch(loginCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(loginSuccess(res.data.data));
      addSession(res.data.data);
      setHeader(res.data.data.token);
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(loginFailure(err));
      dispatch(apiFailure(err));
    });
};
