const musicsDataSuccess = (payload) => ({
  type: "MUSICSDATA_SUCCESS",
  payload,
});

const musicsDataFailure = (error) => ({
  type: "MUSICSDATA_FAILURE",
  error,
});

const resetmusicsDataFlag = () => ({ type: "MUSICSDATA_RESET" });

const musicsDataCall = () => ({
  type: "MUSICSDATA_API_CALL",
});
export {
  musicsDataSuccess,
  musicsDataFailure,
  resetmusicsDataFlag,
  musicsDataCall,
};
